import React, { useEffect, useState } from 'react'
import Layout from '../components/layouts'
import { Footer } from '../components/shareable/footer/Footer'
import { Flex } from '../components/primitives'
import { Basket } from '../components/order/Basket'
import { getOrderFromKlarnaId } from '../api/getOrderFromKlarnaId'
import { LoadingSpinner } from '../components/shareable/lottie/LoadingSpinner'

export default ({ navigate }) => {
  const [modalIsOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState(false)
  const [checkout, setCheckout] = useState(false)

  useEffect(() => {
    const klarnaId = new URLSearchParams(window.location.search).get('klarnaId')
    const getOrder = async () => {
      setLoading(true)
      const respone = await getOrderFromKlarnaId({ klarnaId })
      console.log(respone)
      setOrder({ _id: respone.order._id, ...respone.order.customerOrder })
      setCheckout(respone.checkout)
      setLoading(false)
    }

    getOrder()
  }, [])

  return (
    <Layout
      blackNav={true}
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}>
      <Flex jusifyContent={'center'} width={'100%'}>
        <Flex
          mt={['80px', '80px', '120px']}
          maxWidth={'1000px'}
          width={'100%'}
          minHeight={'100vh'}
          p={4}
          flexDirection={'column'}>
          {loading && (
            <Flex
              mt={4}
              width={'100%'}
              jusifyContent={'center'}
              height={'40px'}>
              <Flex width={'100px'} height={'40px'}>
                <LoadingSpinner />
              </Flex>
            </Flex>
          )}
          {checkout && (
            <Basket
              showBackButton={false}
              changeRoom={(rooms) => {
                setOrder({ ...order, rooms: rooms })
                if (rooms.length === 0) {
                  //setPageIndex(1)
                }
              }}
              checkout={checkout}
              rooms={order.rooms}
              orderId={order._id}
              backClick={() => {
                //setPageIndex(pageIndex - 1)
              }}
            />
          )}
        </Flex>
      </Flex>
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
